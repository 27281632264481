






























import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import constant from '~/components/dashboard/widget/collaborate/common/constant'

import CancelledChip from '~/components/dashboard/widget/collaborate/common/displays/CancelledChip.vue'
import RejectedChip from '~/components/dashboard/widget/collaborate/common/displays/RejectedChip.vue'
import TextClamp from '~/components/common/TextClamp.vue'

import CAssigneesDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneesDisplay.vue'
import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CDescriptionDisplay from '~/components/dashboard/widget/collaborate/common/displays/DescriptionDisplay.vue'
import CEventDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/EventDateDisplay.vue'
import CIssueDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/IssueDateDisplay.vue'
import CSectionDisplay from '~/components/dashboard/widget/collaborate/common/displays/SectionDisplay.vue'
import CTaskTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskTitleDisplay.vue'

@Component({
  components: {
    CancelledChip,
    CAssigneesDisplay,
    CBoardDisplay,
    CDescriptionDisplay,
    CIssueDateDisplay,
    CEventDateDisplay,
    CSectionDisplay,
    CTaskTitleDisplay,
    RejectedChip,
    TextClamp,
  },
  name: 'dashboard-collaborate-calendar-view-widget-content-task-display',
})
export default class TaskDisplay extends Vue {
  @Prop()
  public dateType: string
  @Prop()
  public task: TaskModel & object
  @Prop()
  public checkFieldCanShow: (key: string) => Boolean

  get isShowEventDate() {
    return this.dateType === constant.dateTypes.eventDate.value
  }

  public checkTaskIsCancelled(task: TaskModel) {
    return task.status === TaskStatus.Cancelled
  }

  public checkTaskIsRejected(task: TaskModel) {
    return task.status === TaskStatus.Rejected
  }

  public mounted() {
    this.$emit('init')
  }
}
