










import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import constant from '~/components/dashboard/widget/collaborate/common/constant'
@Component({
  name: 'dashboard-collaborate-calendar-view-widget-content-calendar-type-select',
})
export default class CalendarTypeSelect extends Vue {
  @Prop()
  public value: string

  get calendarTypes() {
    return Object.values(constant.calendarTypes)
  }
}
