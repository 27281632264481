









































import { Component, Prop } from 'vue-property-decorator'
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns'
import Vue from 'vue'

import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'

import { dateFormat } from '~/filters'
import CalendarViewDataHandler from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/DataHandler.vue'
import CalendarViewEventDisplay from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/EventDisplay.vue'
import CalendarViewTaskDisplay from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/TaskDisplay.vue'
import config from '~/config'
import constant from '~/components/dashboard/widget/collaborate/common/constant'
import util from '~/util'
@Component({
  components: {
    CalendarViewDataHandler,
    CalendarViewEventDisplay,
    CalendarViewTaskDisplay,
  },
  filters: {
    dateFormat,
  },
  name: 'dashboard-collaborate-calendar-view-widget-content-data-listing',
})
export default class DataListing extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public allDataIds: string[]

  public selectedTaskId: Nullable<string> = null
  public selectedElement: any = null
  public selectedOpen: Nullable<boolean> = null

  get calendarType() {
    return this.config.calendarType || constant.calendarTypes.Month.value
  }

  get calendarEndDate() {
    if (this.config.calendarType === constant.calendarTypes.Month.value) {
      return endOfMonth(this.config.date)
    }
    return endOfWeek(this.config.date)
  }

  get calendarStartDate() {
    if (this.config.calendarType === constant.calendarTypes.Month.value) {
      return startOfMonth(this.config.date)
    }
    return startOfWeek(this.config.date)
  }

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get selectedTask() {
    if (this.selectedTaskId) {
      return this.$store.myGetters.getTaskById(this.selectedTaskId)
    }
  }

  get tooltipWidth() {
    return this.isMobile ? '100vw' : '500px'
  }

  public emitInitEvent() {
    this.$emit('init')
  }

  public getEventText(task: any) {
    return task.color
      ? util.determineBrightness(task.color) >= 150
        ? '#333333'
        : '#ffffff'
      : '#1867c0'
  }

  public getEventColor(task: any) {
    return task.color
  }

  public handleClickEvent(e: any, event: TaskModel) {
    this.selectedTaskId = event.id
    this.selectedElement = e.target
    setTimeout(() => (this.selectedOpen = true), 10)
  }

  public handleEventClick(task: TaskModel) {
    const collaborateApp = this.$store.myGetters['accounts/app/currentWorkApps'].find(
      app => app.name === config.appName.collaborate,
    )
    collaborateApp && window.open(`${collaborateApp.url}/locate-task/${task.id}`, '_blank')
  }

  public mounted() {
    this.emitInitEvent()
  }
}
