





import { Component, Prop } from 'vue-property-decorator'
import { GroupConfigKey, TaskStatus } from '@technology/collaborate-next-js'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { addDays, differenceInDays, differenceInMinutes, endOfDay, isSameDay } from 'date-fns'
import Vue from 'vue'

import { dateFormat } from '~/filters'
import constant from '~/components/dashboard/widget/collaborate/common/constant'

@Component({
  name: 'dashboard-collaborate-calendar-view-widget-content-data-handler',
})
export default class DataHandler extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public dataIds: string[]
  @Prop() public allDataIds: string[]

  get slotProps() {
    return {
      tasksMap: this.tasksMap,
      listItems: this.listItems,
      checkFieldCanShow: this.checkFieldCanShow,
    }
  }

  get tasksMap() {
    return this.listItems.reduce((map, task) => {
      let startDatetime = new Date(task.startDatetime)
      let endDatetime = new Date(task.endDatetime)
      let bgColor = undefined
      if (this.config.dateType === constant.dateTypes.pubDate.value) {
        startDatetime = new Date(this.getIssueDateByTask(task) || startDatetime)
        endDatetime = startDatetime
      }
      const category = this.$store.myGetters.getCategoryById(task.categoryId)
      if (category && category.style && category.style.bgColor) {
        bgColor = category.style.bgColor
      } else if (task.status === TaskStatus.Cancelled) {
        bgColor = '#ffa500'
      } else if (task.status === TaskStatus.Rejected) {
        bgColor = '#ab000a'
      }
      const dayDifference: number = differenceInDays(endDatetime, startDatetime)
      for (let i = 0; i <= dayDifference; i++) {
        const targetDatetime = addDays(startDatetime, i)
        const formattedDate = dateFormat(targetDatetime, 'YYYY-MM-DD')
        const formattedTime = dateFormat(targetDatetime, 'HH:mm')
        const eventDuration = this.getDurationByDate(targetDatetime, endDatetime)
        let time = isSameDay(targetDatetime, endDatetime) ? formattedTime : undefined
        if (eventDuration === 0) {
          time = undefined
        }
        map[formattedDate] = map[formattedDate] || []
        map[formattedDate].push({
          ...task,
          color: bgColor,
          time,
          duration: eventDuration,
        })
      }
      return map
    }, {} as KeyMap<any[]>)
  }

  get listItems() {
    return this.dataIds && this.$store.myGetters.getTasksByIds(this.dataIds)
  }

  public getIssueDateByTask(task: TaskModel) {
    const targetTask =
      task.source === 'shared' ? task : this.$store.myGetters.getParentTaskById(task.id)
    return targetTask.articleIssueDate ?? undefined
  }

  private getDurationByDate(startDate: Date, endDate: Date) {
    return isSameDay(startDate, endDate)
      ? differenceInMinutes(endDate, startDate)
      : differenceInMinutes(endOfDay(startDate), startDate)
  }

  get hiddenFields() {
    const formHiddenFields: any = this.$store.myGetters.getGroupConfigByGroupIdAndKey(
      this.config.groupId,
      GroupConfigKey.FormHiddenFields,
    )
    return formHiddenFields ? formHiddenFields : []
  }

  public checkFieldCanShow(key: string) {
    return this.hiddenFields?.indexOf(key) === -1
  }
}
