





import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import CalendarViewAddWidgetDialog from '~/components/dashboard/widget/collaborate/calendarViewWidget/addWidgetDialog/Dialog.vue'

@Component({
  components: {
    CalendarViewAddWidgetDialog,
  },
  name: 'dashboard-collaborate-calendar-view-widget-placeholder',
})
export default class Placeholder extends Vue {}
