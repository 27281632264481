








































import { Component, Prop } from 'vue-property-decorator'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  getDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import Vue from 'vue'

import CloseButton from '~/components/common/widget/buttons/CloseButton.vue'
import CustomButtonGroup from '~/components/common/CustomButtonGroup.vue'
import DateButtonDisplay from '~/components/common/widget/displays/DateButtonDisplay.vue'
import DateControl from '~/components/common/widget/controls/DateControl.vue'
import Preload from '~/components/common/Preload.vue'
import RefreshButton from '~/components/common/widget/buttons/RefreshButton.vue'
import TodayButton from '~/components/common/widget/buttons/TodayButton.vue'
import WidgetNameDisplay from '~/components/common/widget/displays/WidgetNameDisplay.vue'

import CBoardSelect from '~/components/dashboard/widget/collaborate/common/inputs/BoardSelect.vue'
import CalendarViewContent from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/Content.vue'
import CalendarViewHeader from '~/components/dashboard/widget/collaborate/calendarViewWidget/header/Header.vue'
import constant from '~/components/dashboard/widget/collaborate/common/constant'

import { WidgetConfigEntity } from '~/apollo/types'
import { dateFormat } from '~/filters'
import util from '~/util'

@Component({
  components: {
    CBoardSelect,
    CloseButton,
    CalendarViewContent,
    CalendarViewHeader,
    CustomButtonGroup,
    DateButtonDisplay,
    DateControl,
    Preload,
    RefreshButton,
    TodayButton,
    WidgetNameDisplay,
  },
  filters: {
    dateFormat,
  },
  name: 'dashboard-collaborate-calendar-view-widget',
})
export default class TaskCalendarViewWidget extends Vue {
  @Prop() public widget: WidgetConfigEntity
  @Prop() public widgetId: string
  @Prop() public config: KeyMap<any>

  public refreshInterval = 30000
  public enableCronjob = true

  public cancelLoading = false
  public dataLoading = false
  public refreshLoading = false
  public fetchDatetime = new Date()
  public dataIds: string[] = []
  public intervalId: NodeJS.Timeout

  public dateSelected = false

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  public handleCloseBtnClick() {
    this.$alertDialog.showDialog({
      title: 'Warning',
      message: 'Are you sure to remove this widget?',
      buttons: [
        {
          text: 'Yes',
          color: 'primary',
          onClick: async () => {
            this.cancelLoading = true
            await this.$store.myActions.deleteWidgetConfig({
              widgetConfigId: this.widgetId,
            })
            this.cancelLoading = false
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })
  }

  public async handleBoardChange(boardId: string) {
    await this.handleConfigUpdate({
      config: {
        ...this.config,
        boardId,
      },
      loading: true,
    })
    this.fetchData(true, this.getFetchBody(boardId))
  }

  public async handleConfigUpdate({ config, loading }: { config: KeyMap<any>; loading: boolean }) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        config: {
          ...config,
        },
      },
    })
    this.fetchData(loading)
  }

  public async handleRefreshBtnClick() {
    this.sendGAAction('event', 'TCV', 'Click', 'Refresh')
    this.refreshLoading = true
    await this.fetchData(false)
    this.refreshLoading = false
  }

  /**
   * Fetch task assignment widget data from server
   */
  public async fetchData(withLoading = true, body = this.getFetchBody()) {
    this.clearBackgroundJob()
    withLoading && (this.dataLoading = true)
    try {
      const result = await this.$store.myActions.fetchTaskAssignManagement({
        ...body,
      })
      let taskArray = result.data
      if (result.nested && result.nested.relatedTasks) {
        taskArray = [...taskArray, ...result.nested.relatedTasks]
      }
      await Promise.all([
        this.fetchRelatedUser(taskArray),
        this.fetchGroupConfigs(this.config.groupId),
      ])
      // Sort by event datetime
      this.dataIds = result.data
        .sort((a: TaskModel, b: TaskModel) => {
          const dateTimeA = new Date(a.startDatetime).getTime()
          const dateTImeB = new Date(b.startDatetime).getTime()
          if (dateTimeA < dateTImeB) {
            return -1
          }
          if (dateTimeA > dateTImeB) {
            return 1
          }
          return 0
        })
        .map(task => task.id)
      this.fetchDatetime = new Date()
    } catch (e) {
      const err = e as any
      if (util.isObject(err.body)) {
        const { code, message } = err.body
        this.$store.myActions.SHOW_DIALOG({
          app: 'collaborate',
          type: 'error',
          code,
          description: message,
        })
      } else {
        console.log(err)
      }
    }
    withLoading && (this.dataLoading = false)
    this.enableCronjob && this.setNextJob()
  }

  public async fetchGroupConfigs(groupId: string) {
    if (util.checkEmpty(this.$store.myGetters.getGroupConfigByGroupId(groupId))) {
      return this.$store.myActions.fetchGroupConfigs()
    }
  }

  public async fetchRelatedUser(tasks: TaskModel[]) {
    const assigneeIds = util
      .distinct(util.flatten(tasks.map(task => [...(task.assigneeIds || []), task.createdBy])))
      .filter(
        assigneeId =>
          !util.checkEmpty(assigneeId) &&
          !this.$store.myGetters['accounts/user/getById'](assigneeId),
      )
    if (assigneeIds.length > 0) {
      await this.$store.myActions['accounts/user/fetchList']({
        q: assigneeIds.join(','),
        type: 'ids',
      })
    }
  }

  // Background job related
  public clearBackgroundJob() {
    this.intervalId && clearTimeout(this.intervalId)
  }

  public setNextJob() {
    this.intervalId = setTimeout(async () => {
      this.refreshLoading = true
      await this.fetchData(false)
      this.refreshLoading = false
    }, this.refreshInterval)
  }

  public async created() {
    this.sendGAAction('event', 'TCV', 'Load')
    await this.fetchData(false)
  }

  public destroyed() {
    this.enableCronjob = false
    this.clearBackgroundJob()
  }

  private getFetchBody(boardId: string = '') {
    // Get Sunday of first weeks
    let startDate = addDays(startOfMonth(this.config.date), -getDay(startOfMonth(this.config.date)))
    // Get Saturday of last weeks
    let endDate = addDays(endOfMonth(this.config.date), 6 - getDay(endOfMonth(this.config.date)))
    if (this.config?.calendarType === constant.calendarTypes.Week.value) {
      startDate = startOfWeek(this.config.date)
      endDate = endOfWeek(this.config.date)
    }
    const baseBody: SearchWithNestedParams = {
      taskSearch: {
        groupIds: [this.config.groupId],
      },
    }
    const localBoardId = boardId || this.config.boardId
    if (localBoardId && localBoardId !== 'all') {
      baseBody.taskSearch.boardIds = [localBoardId]
    }
    if (this.config.dateType === constant.dateTypes.eventDate.value) {
      baseBody.taskSearch.eventDateStart = startOfDay(startDate).toISOString()
      baseBody.taskSearch.eventDateEnd = endOfDay(endDate).toISOString()
    } else {
      baseBody.taskSearch.issueDateStart = startOfDay(startDate).toISOString()
      baseBody.taskSearch.issueDateEnd = endOfDay(endDate).toISOString()
    }
    return baseBody
  }
}
