
















import { Component, Prop } from 'vue-property-decorator'
import { TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskStatus } from '@technology/collaborate-next-js'
import Vue from 'vue'

import NoAssigneeChip from '~/components/dashboard/widget/collaborate/common/displays/NoAssigneeChip.vue'
import TextClamp from '~/components/common/TextClamp.vue'

import CAssigneeDisplay from '~/components/dashboard/widget/collaborate/common/displays/AssigneeDisplay.vue'
import CTaskTitleDisplay from '~/components/dashboard/widget/collaborate/common/displays/TaskTitleDisplay.vue'

@Component({
  components: {
    CAssigneeDisplay,
    CTaskTitleDisplay,
    NoAssigneeChip,
    TextClamp,
  },
  name: 'dashboard-collaborate-calendar-view-widget-content-event-display',
})
export default class EventDisplay extends Vue {
  @Prop()
  public task: TaskModel & object
  @Prop()
  public color: string
  @Prop()
  public textColor: string

  public numberOfAssignees = 2

  get customStyle() {
    return {
      'background-color': this.color,
      color: this.textColor,
    }
  }

  get taskIsCancelled() {
    return this.task.status === TaskStatus.Cancelled
  }

  get taskIsRejected() {
    return this.task.status === TaskStatus.Rejected
  }

  get partOfAssignees() {
    return (
      (this.task.assigneeIds || [])
        .slice(0, this.numberOfAssignees)
        .map(assigneeId => this.$store.myGetters['accounts/user/getById'](assigneeId)) || []
    )
  }

  get titleWithStatus() {
    let taskTitle = this.task.title
    if (this.taskIsCancelled) {
      return `Cancelled - ${taskTitle}`
    } else if (this.taskIsRejected) {
      return `Rejected - ${taskTitle}`
    } else {
      return taskTitle
    }
  }

  public shouldShowMore(index: number) {
    if (
      this.task.assigneeIds &&
      this.task.assigneeIds.length > this.numberOfAssignees &&
      index === this.numberOfAssignees - 1
    ) {
      return true
    }
    return false
  }

  public mounted() {
    this.$emit('init')
  }
}
