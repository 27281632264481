












































































import { Component, Prop } from 'vue-property-decorator'
import { TaskStatus } from '@technology/collaborate-next-js'
import {
  addMonths,
  addWeeks,
  getISOWeek,
  getMonth,
  getYear,
  setMonth,
  setYear,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns'
import Vue from 'vue'

import { dateFormat } from '~/filters'
import Collapse from '~/components/common/Collapse.vue'
import constant from '~/components/dashboard/widget/collaborate/common/constant'

import CounterDisplay from '~/components/common/widget/displays/CounterDisplay.vue'
import MonthSelect from '~/components/common/widget/selects/MonthSelect.vue'
import YearSelect from '~/components/common/widget/selects/YearSelect.vue'

import CDateTypeSelect from '~/components/dashboard/widget/collaborate/common/inputs/DateTypeSelect.vue'

import CalenderViewCalendar from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/Calendar.vue'
import CalenderViewCalendarTypeSelect from '~/components/dashboard/widget/collaborate/calendarViewWidget/content/CalendarTypeSelect.vue'

import util from '~/util'
@Component({
  components: {
    Collapse,
    CDateTypeSelect,
    CounterDisplay,
    CalenderViewCalendar,
    CalenderViewCalendarTypeSelect,
    MonthSelect,
    YearSelect,
  },
  name: 'dashboard-collaborate-calendar-view-widget-content',
})
export default class Content extends Vue {
  @Prop()
  public config: KeyMap<any>
  @Prop()
  public dataIds: string[]

  public dateType: string = constant.dateTypes.pubDate.value
  public calendarType: string = constant.calendarTypes.Month.value
  public wrapperElement: any = null
  public calcTargetElement: any = null
  public cancelledSelected = false
  public rejectedSelected = false
  public $refs: any
  public currentYear = parseInt(dateFormat(new Date(), 'YYYY'))
  public filterFunctionChain: KeyMap<(chainedDataIds: string[], dataIds: string[]) => string[]> = {}

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get selectedMonth() {
    return getMonth(this.config.date)
  }

  get selectedMonthlyWeek() {
    return getISOWeek(this.config.date) - getISOWeek(startOfMonth(this.config.date)) + 1
  }

  get selectedYear() {
    return dateFormat(this.config.date, 'YYYY')
  }

  get startingYear() {
    return this.currentYear - 7
  }

  get endingYear() {
    return this.currentYear + 7
  }

  get filteredDataIds() {
    const taskIds = this.tasks.map(task => task.id)
    // Recalculate the collapse height
    this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)
    if (Object.keys(this.filterFunctionChain).length) {
      const keysOrdering = Object.keys(this.filterFunctionChain)
      const filteredResult = keysOrdering.reduce(
        (filteredDataIds: string[], keys) =>
          this.filterFunctionChain[keys](filteredDataIds, taskIds),
        [],
      )
      // Remove duplicated value
      return util.distinct(filteredResult)
    }
    return taskIds
  }

  get cancelledTaskCount() {
    return this.tasks.filter(task => task.status === TaskStatus.Cancelled).length
  }

  get rejectedTaskCount() {
    return this.tasks.filter(task => task.status === TaskStatus.Rejected).length
  }

  get totalTaskCount() {
    return this.tasks.length
  }

  get totalTaskSelected() {
    return !this.cancelledSelected && !this.rejectedSelected
  }

  get tasks() {
    return this.$store.myGetters.getTasksByIds(this.dataIds)
  }

  /**
   * Filter by current user
   */
  public handleCollapseChange(collapseState: number) {
    this.$emit('config-update', {
      config: {
        ...this.config,
        collapseState,
      },
      loading: false,
    })
  }

  public handleTodayClick() {
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: dateFormat(new Date(), 'YYYY-MM-DD'),
      },
      loading: true,
    })
  }

  public handlePrevClick() {
    let date =
      this.calendarType === constant.calendarTypes.Week.value
        ? addWeeks(this.config.date, -1)
        : addMonths(this.config.date, -1)
    if (getYear(date) < this.startingYear) {
      date = this.config.date
    }
    let formattedDate = dateFormat(
      this.calendarType === constant.calendarTypes.Week.value
        ? startOfISOWeek(date)
        : startOfMonth(date),
      'YYYY-MM-DD',
    )
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: formattedDate,
      },
      loading: true,
    })
  }

  public handleNextClick() {
    let date =
      this.calendarType === constant.calendarTypes.Week.value
        ? addWeeks(this.config.date, 1)
        : addMonths(this.config.date, 1)
    if (getYear(date) > this.endingYear) {
      date = this.config.date
    }
    let formattedDate = dateFormat(
      this.calendarType === constant.calendarTypes.Week.value
        ? startOfISOWeek(date)
        : startOfMonth(date),
      'YYYY-MM-DD',
    )
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: formattedDate,
      },
      loading: true,
    })
  }

  public handleMonthChange(month: number) {
    const date = setMonth(this.config.date, month)
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: dateFormat(startOfMonth(date), 'YYYY-MM-DD'),
      },
      loading: true,
    })
  }

  public handleYearChange(year: number) {
    const date = setYear(this.config.date, year)
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: dateFormat(startOfMonth(date), 'YYYY-MM-DD'),
      },
      loading: true,
    })
  }

  public handleDateTypeChange(dateType: string) {
    this.dateType = dateType
    this.$emit('config-update', {
      config: {
        ...this.config,

        dateType,
      },
      loading: true,
    })
  }

  public handleCalendarTypeSelectChange(calendarType: string) {
    this.calendarType = calendarType
    // set date to today change date type
    this.$emit('config-update', {
      config: {
        ...this.config,
        date: dateFormat(new Date(), 'YYYY-MM-DD'),
        calendarType,
      },
      loading: true,
    })
  }

  public handleAllClick() {
    this.cancelledSelected = false
    this.rejectedSelected = false
    Vue.delete(this.filterFunctionChain, 'cancelled')
    Vue.delete(this.filterFunctionChain, 'rejected')
  }

  public handleCancelledClick() {
    this.cancelledSelected = !this.cancelledSelected
    if (this.cancelledSelected) {
      Vue.set(
        this.filterFunctionChain,
        'cancelled',
        (chainedDataIds: string[], dataIds: string[]) =>
          chainedDataIds.concat(
            this.$store.myGetters
              .getTasksByIds(dataIds)
              .filter(task => task.status === TaskStatus.Cancelled)
              .map(task => task.id),
          ),
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'cancelled')
    }
  }

  public handleRejectedClick() {
    this.rejectedSelected = !this.rejectedSelected
    if (this.rejectedSelected) {
      Vue.set(this.filterFunctionChain, 'rejected', (chainedDataIds: string[], dataIds: string[]) =>
        chainedDataIds.concat(
          this.$store.myGetters
            .getTasksByIds(dataIds)
            .filter(task => task.status === TaskStatus.Rejected)
            .map(task => task.id),
        ),
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'rejected')
    }
  }

  public handleDataPresentInit() {
    const wrapperElement = this.$refs.dataPresentRef.$el
    const calcTargetElements =
      this.$refs.dataPresentRef.$el.getElementsByClassName('calendar-view-calendar')
    if (wrapperElement && calcTargetElements.length) {
      this.wrapperElement = wrapperElement
      this.calcTargetElement = calcTargetElements[0]
      if (this.wrapperElement && this.calcTargetElement) {
        // Recalculate the collapse height
        this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)
      }
    }
  }

  public mounted() {
    this.dateType = this.config.dateType || constant.dateTypes.pubDate.value
    this.calendarType = this.config.calendarType || constant.calendarTypes.Month.value
  }
}
